import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"testimonials"} />
		<Helmet>
			<title>
				Відгуки | Arena Футбольний навчальний центр
			</title>
			<meta name={"description"} content={"Досвід вдосконалено в досконалості"} />
			<meta property={"og:title"} content={"Відгуки | Arena Футбольний навчальний центр"} />
			<meta property={"og:description"} content={"Досвід вдосконалено в досконалості"} />
			<link rel={"shortcut icon"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<meta name={"msapplication-TileImage"} content={"https://ua.tryzubtraditions.com/img/5043402.png"} />
			<meta name={"msapplication-TileColor"} content={"https://ua.tryzubtraditions.com/img/5043402.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" background="#46728a">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 56px 0px"
				width="100%"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline1"
					text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Відгуки{"\n\n"}
				</Text>
				<Text margin="0px 0px 0px 0px" color="--lightD2" text-align="center" font="--lead">
					Справжні історії.Справжні переживання.Заняття на з перших вуст розповіді наших членів і зрозуміти, що для них означає мандрівка вершини.Ці голоси втілюють вершину трансформації та досконалості у лютому футтауніні Новчальнян, обіцяють і виконують.
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				align-items="stretch"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="16px"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text
						margin="0px 0px 100px 0px"
						color="--lightD2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Приєднання вершини було одним з найкращих рішень у моєму житті.Якість тренувань, відданість тренерів та спільноти тут є неперевершеними.Мої навички не тільки різко покращилися, але й здобули оновлену пристрасть до гри.{"\n\n"}
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--light" font="--base" margin="0px 0px 2px 0px">
								Олег
							</Text>
							<Text color="--lightD2" font="--base" margin="0px 0px 0px 0px" opacity=".5">
								34
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-margin="0px 0px 0px 0px"
					lg-padding="0px 0px 0px 15px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text
						margin="0px 0px 100px 0px"
						color="--lightD2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Я був у кількох лютому фунтбельнийнхалнях, але в минулому, але ніхто не такий, як вершина.Тут вони розуміють, що мова йде не лише про гру, а й про індивід.Кожен сеанс підштовхує мене до кращого, і я ніколи не відчував більше співзвучного футболу.
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--light" font="--base" margin="0px 0px 2px 0px">
								Віталій
							</Text>
							<Text color="--lightD2" font="--base" margin="0px 0px 0px 0px" opacity=".5">
								32
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					margin="0px 30px 0px 0px"
					lg-padding="0px 15px 0px 0px"
					lg-margin="40px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text
						margin="0px 0px 100px 0px"
						color="--lightD2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Як хтось, хто взяв футбол пізніше в житті, я спочатку вагався з приєднанням до навчального центру.Але вершина вітала мене з розпростертими обіймами.Адаптивні тренування та розумові майстерні сесії були зміною гри.Я не тільки граю краще, але й краще думаю на полі.{"\n\n"}
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--light" font="--base" margin="0px 0px 2px 0px">
								Ігор
							</Text>
							<Text color="--lightD2" font="--base" margin="0px 0px 0px 0px" opacity=".5">
								30
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					lg-padding="0px 0px 0px 15px"
					lg-margin="40px 0px 0px 0px"
					sm-padding="0px 0px 0px 0px"
					sm-margin="40px 0px 0px 0px"
					display="flex"
					flex-direction="column"
				>
					<Text margin="0px 0px 0px 0px" color="--orange" font="--headline1">
						“
					</Text>
					<Text
						margin="0px 0px 100px 0px"
						color="--lightD2"
						font="--lead"
						lg-margin="0px 0px 30px 0px"
						sm-margin="0px 0px 30px 0px"
						flex="1 0 auto"
					>
						Навколишнє середовище у вершині є електричним.Суміш суворої підготовки та моментів товариства зробило мій час тут незабутнім.Незалежно від того, чи вдосконалює це техніку чи ділиться сміхом після сеансу, кожна мить у вершині додає цінності моїй футбольній подорожі.
					</Text>
					<Box display="flex" margin="0px 17px 0px 0px" align-items="flex-start">
						<Box>
							<Text color="--light" font="--base" margin="0px 0px 2px 0px">
								Степан
							</Text>
							<Text color="--lightD2" font="--base" margin="0px 0px 0px 0px" opacity=".5">
								36
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="#46728a">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--light"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Ваша історія чекає{"\n\n"}
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--light"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Ми пишаємось історіями, які наші члени створили з нами. Готові розпочати власну мандрівку вершини та бути частиною цієї спадщини?Приєднуйтесь до нас сьогодні і нехай ваш відгук буде наступним натхненням для багатьох.
				</Text>
				<Link
					href="/contacts"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Зв'яжіться з нами
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fe240cd81ee001f7dd8c4"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});